import { styled } from "@mui/system";

const TextContent = styled("div")(({ theme, isNotMobileScreen }) => ({
  color: theme.palette.neutral.light,
  fontSize: "22px",
  // padding: isNotMobileScreen ? "188px 20vw 80px" : "87px 4vw 15px", // 5vh, 7vh
  width: "1000px",
  // textAlign: "justify",
  backgroundImage: `url("/assets/logo-2ST.svg")`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: isNotMobileScreen ? "300px" : "200px",
}));

export default TextContent;
