export const colorTokens = {
  grey: {
    0: "#e8e8e8", //
    10: "#f3efe5",
    50: "#e6e1d7",
    100: "#c7c3b5", //
    200: "#b1ac9c", //
    300: "#afa88f", //
    400: "#afa88f",
    500: "#3c4035", // Green
    600: "#4D4D4D",
    700: "#2b2b33", //
    800: "#afa88f", //
    900: "#0A0A0A",
    1000: "#000000",
  },
  primary: {
    50: "#f3efe5",
    100: "#ff2323",
    200: "#b10000",
    300: "#950000",
    400: "#740000",
    500: "#390000",
    600: "#000000",
  },
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      ...{
        primary: {
          darker: colorTokens.primary[50],
          dark: colorTokens.primary[100],
          main: colorTokens.primary[200],
          light: colorTokens.primary[500],
        },
        neutral: {
          darker: colorTokens.grey[0],
          dark: colorTokens.grey[50],
          main: colorTokens.grey[200],
          mediumMain: colorTokens.grey[300],
          medium: colorTokens.grey[400],
          shade: colorTokens.grey[500],
          light: colorTokens.grey[700],
          lighter: colorTokens.grey[800],
        },
        background: {
          default: colorTokens.grey[10],
          alt: colorTokens.grey[0],
        },
      },
    },
    typography: {
      fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["adobe-jenson-pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
