import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export default function PlanDialog({ open, handleClose, params }) {
  const { palette } = useTheme();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isZoomed, setIsZoomed] = useState(false);
  const imageRef = useRef(null);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  useEffect(() => {
    setIsZoomed(false);
  }, [open === false]); // eslint-disable-line

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      m="1rem"
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${palette.primary.main}`,
          fontSize: "1.5rem",
        }}
      >{`${params.flat} – ${params.floor}`}</DialogTitle>
      <DialogContent sx={{ backgroundColor: palette.primary.darker }}>
        <img
          ref={imageRef}
          src={
            params.floor === "UG"
              ? `/assets/flats/${params.floor}.png`
              : `/assets/flats/${params.flat}-${params.floor}.png`
          }
          alt={params.floor}
          onClick={(e) => toggleZoom(e)}
          style={{
            width: isZoomed ? "200%" : "100%",
            cursor: isZoomed ? "zoom-out" : "zoom-in",
            transition: "0.25s ease-in-out",
          }}
        />
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${palette.primary.main}` }}>
        <Button onClick={handleClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
