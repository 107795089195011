import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import FlatNav from "./FlatNav";
import { Outlet } from "react-router-dom";

export default function FlatComponent() {
  const { palette } = useTheme();

  return (
    <>
      <Typography
        variant="h3"
        color={palette.primary.main}
        textAlign={"center"}
      >
        Etage
      </Typography>
      <FlatNav items={["UG", "EG", "1. OG", "2. OG"]} />
      <Outlet />
    </>
  );
}
