import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function Cookies() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  document.title = "Schlosspark Residenz | Cookies";

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          Cookies
        </Typography>
        Wir verwenden keine Cookies für die Erfassung von Benutzerdaten oder für
        Werbezwecke.
      </TextContent>
    </MaxWidthWrapper>
  );
}
