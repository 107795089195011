import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function DataProtection() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  document.title = "Schlosspark Residenz | Datenschutzrichtlinie";
  const { palette } = useTheme();

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          Datenschutzrichtlinie
        </Typography>
        <Typography fontSize="18px">
          Diese Datenschutzrichtlinie erklärt, wie wir Daten sammeln, verwenden
          und schützen, die im Zusammenhang mit der Nutzung unserer Website
          erhoben werden. Durch die Nutzung unserer Website erklären Sie sich
          mit den in dieser Richtlinie beschriebenen Praktiken einverstanden.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Erhobene Daten
        </Typography>
        <Typography fontSize="18px">Wir erfassen folgende Daten:</Typography>
        <ul>
          <li>
            <Typography fontSize="18px">
              Einstellungen für den Hell- und Dunkelmodus: Wir erfassen
              Informationen darüber, welche Anzeigeeinstellungen (Lichtmodus
              oder Dunkelmodus) Sie auf unserer Website bevorzugen.
            </Typography>
          </li>
        </ul>
        <Typography fontSize="18px">
          Diese Informationen werden nur lokal in Ihrem Browser gespeichert und
          nicht an uns übertragen.
        </Typography>
        <ul>
          <li>
            <Typography fontSize="18px">
              Kontaktformular: Wenn Sie unser Kontaktformular verwenden,
              erfassen wir die folgenden Daten: E-Mail-Adresse, Telefonnummer
              (optional), Name und eine Nachricht. Diese Daten werden
              ausschließlich dazu verwendet, um mit Ihnen in Kontakt zu treten.
              Die Daten werden nur in den E-Mails gespeichert und nicht
              anderweitig genutzt.
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Verwendung der Daten
        </Typography>
        <Typography fontSize="18px">
          Die gesammelten Daten werden ausschließlich für interne Zwecke
          verwendet, um Ihr Website-Erlebnis anzupassen und zu verbessern. Wir
          verwenden diese Daten, um:
        </Typography>
        <ul>
          <li>
            <Typography fontSize="18px">
              Ihnen eine benutzerdefinierte Website-Erfahrung basierend auf
              Ihren Einstellungen zu bieten.
            </Typography>
          </li>
          <li>
            <Typography fontSize="18px">
              Unsere Website zu verbessern und zu optimieren.
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Änderungen an dieser Datenschutzrichtlinie
        </Typography>
        <Typography fontSize="18px">
          Diese Datenschutzrichtlinie kann aktualisiert werden, um Änderungen in
          unseren Datenschutzpraktiken widerzuspiegeln. Wir empfehlen Ihnen,
          diese Seite regelmäßig auf Aktualisierungen zu überprüfen. Änderungen
          treten sofort in Kraft, sobald sie auf dieser Seite veröffentlicht
          werden.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Kontaktinformationen
        </Typography>
        <Typography fontSize="18px">
          Bei Fragen oder Bedenken zur Datenschutzrichtlinie oder zur
          Verarbeitung Ihrer Daten können Sie uns unter
          info@Residenz-Bueckeburg.de erreichen.
        </Typography>
      </TextContent>
    </MaxWidthWrapper>
  );
}
