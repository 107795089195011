import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import CookieBanner from "components/CookieBanner";
import HomePage from "pages/HomePage";
import SitePage from "pages/SitePage";
import ContactPage from "pages/ContactPage";
import FlatsPage from "pages/FlatsPage";
import NotFound from "pages/other/NotFound";
import Impressum from "pages/other/Impressum";
import TermsOfService from "pages/other/TermsOfService";
import DataProtection from "pages/other/DataProtection";
import Cookies from "pages/other/Cookies";
import FlatComponent from "components/flats/FlatComponent";
import FloorComponent from "components/flats/FloorComponent";

function App() {
  const theme = useMemo(() => createTheme(themeSettings())); // eslint-disable-line

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/site" element={<SitePage />} />

          <Route path="/contact" element={<ContactPage />} />

          <Route path="/flats" element={<FlatsPage />}>
            <Route path=":flat" element={<FlatComponent />}>
              <Route path=":floor" element={<FloorComponent />} />
            </Route>
          </Route>

          <Route path="/legal-disclosure" element={<Impressum />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<DataProtection />} />
          <Route path="/cookie-policy" element={<Cookies />} />

          <Route path="*" element={<NotFound pageTitle={"404"} />} />
        </Routes>
        <CookieBanner />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
