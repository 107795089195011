import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function TermsOfService() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  document.title = "Schlosspark Residenz | TOS";

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          Nutzungsbedingungen
        </Typography>
        <Typography fontSize="18px">
          Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor Sie
          die Residenz-Bueckeburg Website (den "Dienst") nutzen.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Zustimmung
        </Typography>
        <Typography fontSize="18px">
          Durch den Zugriff auf die Website erklären Sie sich damit
          einverstanden, an diese Bedingungen gebunden zu sein. Wenn Sie nicht
          mit allen Bedingungen einverstanden sind, dürfen Sie nicht auf die
          Website zugreifen.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Nutzungslizenz
        </Typography>
        <Typography fontSize="18px">
          Einige Inhalte der Website sind urheberrechtlich geschützt und dürfen
          nicht ohne Erlaubnis kopiert, modifiziert, vervielfältigt, übertragen,
          veröffentlicht, angezeigt oder verkauft werden.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Geistiges Eigentum
        </Typography>
        <Typography fontSize="18px">
          Die Marken, Logos und Dienstleistungsmarken ("Marken") auf der Website
          sind Eigentum von Schlosspark Resident GmbH oder den entsprechenden
          Inhabern. Eine unautorisierte Nutzung der Marken ist untersagt.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Haftungsbeschränkungen
        </Typography>
        <Typography fontSize="18px">
          Wir übernehmen keine Haftung für Schäden, die durch die Nutzung des
          Dienstes entstehen können. Dies schließt direkte, indirekte,
          zufällige, spezielle und Folgeschäden ein.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Änderungen und Kündigung
        </Typography>
        <Typography fontSize="18px">
          Wir behalten uns das Recht vor, diese Bedingungen jederzeit ohne
          Vorankündigung zu ändern. Die fortgesetzte Nutzung des Dienstes nach
          der Veröffentlichung von Änderungen bedeutet, dass Sie diese
          Änderungen akzeptieren.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Datenschutz
        </Typography>
        <Typography fontSize="18px">
          Unsere Datenschutzrichtlinie erklärt, wie wir Informationen sammeln,
          verwenden und offenlegen, die im Zusammenhang mit Ihrer Nutzung des
          Dienstes stehen. Durch die Nutzung des Dienstes erklären Sie sich mit
          unserer Datenschutzrichtlinie und der Cookie-Richtlinie einverstanden.
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Sonstige Bestimmungen
        </Typography>
        <Typography fontSize="18px">
          Unbefugte Nutzung des Dienstes zu illegalen Zwecken oder Versuche,
          sich Zugang zu nicht autorisierten Bereichen unserer Website zu
          verschaffen, sind strengstens untersagt. Die Nutzung von Inhalten der
          Website für kommerzielle Zwecke ohne unsere ausdrückliche Genehmigung
          ist nicht gestattet.
        </Typography>
      </TextContent>
    </MaxWidthWrapper>
  );
}
