import { Box } from "@mui/material";
import ButtonNavbar from "components/ButtonNavbar";
import React from "react";

export default function FlatNav({ items }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {items.map((item, i) => (
        <ButtonNavbar key={i} text={item} destination={item} width="130px" />
      ))}
    </Box>
  );
}
