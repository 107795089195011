import { useTheme } from "@emotion/react";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import ContactForm from "components/ContactForm";
import TextContent from "components/TextContent";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import MaxWidthWrapper from "components/MaxWidthWrapper";

export default function ContactPage() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  document.title = "Schlosspark Residenz | Kontakt";
  const { palette } = useTheme();

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          Nehmen Sie Kontakt auf!
        </Typography>
        <Typography fontSize={"18px"}>
          Sie haben Fragen zu unserem Geschäftsmodell? Interessieren sich für
          Wohnflächen? Sie sind ein Bauunternehmer? Dann nehmen Sie gerne
          Kontakt mit uns auf. Wir freuen uns über Ihre Nachricht - telefonisch,
          per E-Mail oder durch unser Kontaktformular.
        </Typography>
        <Box
          mt="1rem"
          sx={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
        >
          <PhoneIphoneIcon />
          <Link href="tel:+491785110105" sx={{ fontSize: "16px" }}>
            +49 178 5110105
          </Link>
        </Box>
        <Box
          mt="1rem"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
          }}
        >
          <EmailIcon />
          <Link
            href="mailto:info@Residenz-Bueckeburg.de"
            sx={{ fontSize: "16px" }}
            target="_blank"
          >
            info@Residenz-Bueckeburg.de
          </Link>
        </Box>
        <ContactForm />
      </TextContent>
    </MaxWidthWrapper>
  );
}
