import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function Footer() {
  const mode = useSelector((state) => state.mode);
  const language = useSelector((state) => state.language);
  const { palette } = useTheme();

  return (
    <footer style={{ marginTop: "auto" }}>
      <div
        style={{
          backgroundColor:
            mode === "dark" ? palette.neutral.dark : palette.neutral.main,
          minHeight: "16px",
        }}
      ></div>
      <div
        className="footer"
        style={{
          backgroundColor:
            mode === "dark" ? palette.neutral.main : palette.neutral.shade,
          minHeight: "6vh",
        }}
      >
        <div className="footerContainer">
          <NavLink className="footerLinks" to="/">
            Home
          </NavLink>
          <NavLink className="footerLinks" to="/site">
            Lage
          </NavLink>
          <NavLink className="footerLinks" to="/contact">
            Kontakt
          </NavLink>
          <NavLink className="footerLinks" to="/flats">
            Wohnungen
          </NavLink>
        </div>
        <div style={{ height: "1rem" }}></div>
        <div className="footerContainer">
          <NavLink className="policy" to="/legal-disclosure">
            {language === "german" ? "Impressum" : "Legal Disclosure"}
          </NavLink>
          <NavLink className="policy" to="/cookie-policy">
            {language === "german" ? "Cookie-Richtlinie" : "Cookie Policy"}
          </NavLink>
          <NavLink className="policy" to="/privacy-policy">
            {language === "german" ? "Datenschutzrichtlinie" : "Privacy Policy"}
          </NavLink>
          <NavLink className="policy" to="/terms-of-service">
            {language === "german" ? "Nutzungsbedingungen" : "Terms of Service"}
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
