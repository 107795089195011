import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PlanDialog from "./PlanDialog";

export default function FloorComponent() {
  const params = useParams();
  const { palette } = useTheme();

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        background: `linear-gradient(
      to bottom,
      ${hexToRGBA(palette.primary.darker, 0)} 0%,
      ${hexToRGBA(
        palette.primary.darker,
        0.9
      )} 20%, /* Adjust this value to control the midpoint */
      ${hexToRGBA(
        palette.primary.darker,
        1
      )} 75% /* Adjust this value to finish the transition sooner */
    )`,
      }}
    >
      <Box
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => handleOpen()}
      >
        <img
          src={
            params.floor === "UG"
              ? `/assets/flats/${params.floor}.png`
              : `/assets/flats/${params.flat}-${params.floor}.png`
          }
          alt={params.floor}
          style={{
            width: "100%",
          }}
        />
      </Box>
      <PlanDialog open={open} handleClose={handleClose} params={params} />
    </Box>
  );
}
