import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function NotFound() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  document.title = "Schlosspark Residenz | 404";
  const { palette } = useTheme();

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          404
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade}>
          Die Seite konnte nicht gefunden werden, bitte überprüfen Sie den Link.
        </Typography>
      </TextContent>
    </MaxWidthWrapper>
  );
}
