import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "german",
  isCookiesAccepted: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Cookies
    setCookiePreferences: (state, action) => {
      state.isCookiesAccepted = action.payload.isCookiesAccepted;
    },
  },
});

export const { setCookiePreferences } = authSlice.actions;
export default authSlice.reducer;
