import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";
import FlatNav from "components/flats/FlatNav";
import { Outlet } from "react-router-dom";

export default function FlatsPage() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  document.title = "Schlosspark Residenz | Wohnungen";
  const { palette } = useTheme();

  return (
    <MaxWidthWrapper>
      <TextContent isNotMobileScreen={isNotMobileScreen}>
        <Box
          sx={{
            padding: isNotMobileScreen ? "188px 0 40px" : "87px 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            STILVOLL, ELEGANT & MODERNSTER LUXUS.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Klassische Architektur kombiniert mit moderner Bauweise auf einem
            ca. 2.500 m² parkähnlichen Grundstück
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="1rem">
            Direkter Zugang zum Schlosspark, Wohnungen verteilt auf vier, in den
            Schlosspark harmonisch eingefügte Baukörper
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="1rem">
            Wohnflächen von ca. 65 m² bis 160 m²
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="1rem">
            Lichte Raumhöhe von 2,80 m, private Gärten, Balkone und
            Dachterrassen
          </Typography>
        </Box>
        <Typography
          variant="h3"
          color={palette.primary.main}
          textAlign={"center"}
        >
          Gebäude
        </Typography>
        <Box
          sx={{
            margin: `0 ${isNotMobileScreen ? "10rem" : "3rem"}`,
          }}
        >
          <img
            src="/assets/flats/residences.png"
            alt="Architektur Plan Gebäude"
            style={{
              borderRadius: "10px",
              width: "100%",
            }}
          />
        </Box>
        <FlatNav items={["Alle", "I", "II", "III", "IV"]} />
        <Box
          sx={{
            margin: "0.5rem 1rem",
          }}
        >
          <img
            src="/assets/flats/render1.jpg"
            alt="BB Gebäude Render"
            style={{
              borderRadius: "10px",
              width: "100%",
            }}
          />
        </Box>
        <Outlet />
      </TextContent>
    </MaxWidthWrapper>
  );
}
