import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function HomePage() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  document.title = "Schlosspark Residenz | Home";

  return (
    <MaxWidthWrapper>
      <TextContent isNotMobileScreen={isNotMobileScreen}>
        <img
          src="/assets/alley.png"
          alt="alley"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            padding: isNotMobileScreen ? "0 0 40px" : "10px 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            DIE EINZIGARTIGKEIT IST DER WAHRE LUXUS.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Wir präsentieren Ihnen eine besondere Residenz in exklusiver Lage.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"}>
            Außergewöhnliche Architektur, ansprechendes Design verbunden mit
            erlesenen Materialien in perfekter Ausführung. Erleben Sie den Luxus
            und die Lebensqualität unter einem Dach.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Wolfgang Fuhrmann & Otto-Constantin Wisskirchen
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mb="1rem">
            Geschäftsführer, Schlosspark Residenz GmbH
          </Typography>
        </div>
        <img
          src="/assets/castle.png"
          alt="castle"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            padding: isNotMobileScreen ? "0 0 80px" : "0px 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            TRADITION & KULTUR TRIFFT EXKLUSIVES WOHNEN.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Wohnen und Leben angrenzend an einen der schönsten Parks in
            Niedersachsen, in unmittelbarer Nähe zum Schloss Bückeburg. Hier
            erwartet Sie die Exklusivität des privat geführten Fürstentums zu
            Schaumburg-Lippe.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Genießen Sie Wohnen in einem historischen und luxuriösen Ambiente
            mit allen Annehmlichkeiten einer hohen Lebensqualität.
          </Typography>
        </div>
        <img
          src="/assets/history.png"
          alt="castle"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            padding: isNotMobileScreen ? "0 0 80px" : "0 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            EIN ORT MIT WAHRER HISTORIE.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Ursprünglich als Gartenanlage mit Anpflanzung seltener Bäume
            angedacht, die zum Teil auch heute noch stehen, diente dieser Ort
            auch als Exerzier-, Reit- und Kutschenübungsplatz.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Im Jahre 1907, zur kaiserlichen Silberhochzeit von Wilhelm II. und
            Auguste Viktoria von Schleswig-Holstein-Sonderburg-Augustenburg,
            wurde daraus ein Jagdpark.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Vier Jahre später als Mausoleumsplatz in einer Linie zum Mausoleum
            umgestaltet, wurde 1920 auf dem Gelände ein Ehrendenkmal errichtet.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Von 1929 bis 1941 wurde hier von Abschlag 2, ein 9-Loch Golfplatz
            bespielt. Die britische Armee nutzte nach Kriegsende bis zum Abzug
            der Truppen das Gelände, anschliessend enstand hier das Gebäude der
            Sauna „Zank“.
          </Typography>
        </div>
      </TextContent>
    </MaxWidthWrapper>
  );
}
