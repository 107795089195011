import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function SitePage() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  document.title = "Schlosspark Residenz | Lage";

  return (
    <MaxWidthWrapper>
      <TextContent isNotMobileScreen={isNotMobileScreen}>
        <img
          src="/assets/map.png"
          alt="alley"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            padding: isNotMobileScreen ? "0 0 40px" : "10px 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            INMITTEN DES SCHAUMBURGER LANDES.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} m="2rem 0 2rem">
            Im vielseitigen Schaumburger Land trifft man auch heute auf
            geschichtsträchtige Kulturstätten und liebevoll gepflegte
            Traditionen. Geprägt durch die Weserrenaissance präsentiert sich die
            Stadt Bückeburg.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"}>
            Auch weiter gelegende Ziele sind durch den fußläufig gelegenen
            Bahnhof und die Autobahnanbindung schnell und komfortabel zu
            erreichen.
          </Typography>
        </div>
        <img
          src="/assets/map2.png"
          alt="castle"
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            padding: isNotMobileScreen ? "0 0 80px" : "0 1rem 15px",
          }}
        >
          <Typography
            variant="h3"
            color={palette.neutral.shade}
            textAlign={"center"}
          >
            IN BESTER NACHBARSCHAFT & LAGE.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Unweit der Landeshauptstadt Niedersachsens liegt Bückeburg.
            Großzügige Parks und historische Architektur prägen das Bild der
            Stadt und laden zum Leben in bester Lage ein.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Modernes Wohnen trifft hier auf Tradition und Historie in
            unmittelbarer Nachbarschaft zum Haus Schaumburg-Lippe.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Hier vereint sich Exklusivität mit Kunst, Kultur und allen
            erdenklichen Annehmlichkeiten unserer Zeit.
          </Typography>
          <Typography textAlign={"center"} fontSize={"18px"} mt="2rem">
            Die Autobahnanbindung und der Bahnhof sind in wenigen Minuten zu
            erreichen.
          </Typography>
        </div>
      </TextContent>
    </MaxWidthWrapper>
  );
}
