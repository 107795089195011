import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import TextContent from "components/TextContent";

export default function Impressum() {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { palette } = useTheme();
  document.title = "Schlosspark Residenz | Impressum";

  return (
    <MaxWidthWrapper>
      <TextContent
        isNotMobileScreen={isNotMobileScreen}
        style={{
          padding: isNotMobileScreen ? "188px 0 80px" : "87px 1rem 15px",
        }}
      >
        <Typography variant="h2" color={palette.primary.main} mb="2rem">
          Impressum
        </Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Herausgeber und Betreiber dieser Internetseite
        </Typography>
        <Typography fontSize="18px">Schlosspark Residenz GmbH</Typography>
        <Typography fontSize="18px"> Burgstr.18</Typography>
        <Typography fontSize="18px">30826 Schloss Ricklingen</Typography>
        <Typography variant="h3" color={palette.neutral.shade} m="3rem 0 1rem">
          Geschäftsführer
        </Typography>
        <Typography fontSize="18px">
          Wolfgang Fuhrmann & Otto-Constantin Wisskirchen
        </Typography>
        <Typography fontSize="18px" mt="1rem">
          Mail: info@Residenz-Bueckeburg.de
        </Typography>
        <Typography fontSize="18px">Handy: +49 178 5110105</Typography>
        <Typography fontSize="18px" mt="1rem">
          Inhaltlich Verantwortlicher gemäß §10 Absatz 3
        </Typography>
        <Typography fontSize="18px">
          MDStV: Schlosspark Resident GmbH
        </Typography>
        <Typography fontSize="18px">Burgstr.18</Typography>
        <Typography fontSize="18px">30826 Schloss Ricklingen</Typography>
      </TextContent>
    </MaxWidthWrapper>
  );
}
